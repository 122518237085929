import React from "react";
import { SearchItem } from "../types/tracks";
import { TrackSelectorItem } from "./trackSelectorItem";

export const TrackSearchResults = ({
  searchItems,
  onSearchItemSelected,
}: {
  searchItems: SearchItem[];
  onSearchItemSelected: ({ track }: { track: SearchItem }) => void;
}) => {
  if (searchItems.length) {
    return (
      <>
        <h3 style={{ margin: 10, textAlign: "left" }}>Choisis ton son 🎵</h3>
        <div>
          {searchItems.map((t) => (
            <TrackSelectorItem
              track={t}
              key={`track-search-${t.id}`}
              onSelected={async ({ track }) => {
                onSearchItemSelected({ track });
              }}
            />
          ))}
        </div>
      </>
    );
  }

  return <></>;
};
