import React, { useState } from "react";

import "./checkmark.css";

import { Track } from "../types/tracks";
import { AudioPlayer } from "./audioPlayer";

export const SelectedTrackItem = ({
  track,
  onTrackFound,
  gameStarted,
}: {
  track: Track;
  onTrackFound: ({ track }: { track: Track }) => void;
  gameStarted: boolean;
}) => {
  const [currentVolume, setVolume] = useState(100);

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 10,
        maxWidth: 400,
      }}
    >
      <div
        style={{ display: "inline-flex", width: "100%", alignItems: "center" }}
      >
        <div>
          <img
            alt={"thumbnail"}
            style={{ width: 50, height: 50, borderRadius: "50%" }}
            src={track.thumbnailURL}
          />
        </div>
        <div
          style={{
            flexDirection: "column",
            width: "100%",
            padding: "0 5px",
            textAlign: "left",
          }}
        >
          <div>{track.name}</div>
          <div>{track.artist}</div>
        </div>
        <div
          style={{ alignSelf: "right", marginTop: 2 }}
          onClick={() => {
            if (track.loading || !gameStarted) {
              return;
            }
            setVolume(0);
            onTrackFound({ track });
          }}
        >
          <span
            className={
              track.found === true || track.loading || !gameStarted
                ? "disabled"
                : ""
            }
            style={{}}
            id="checkmark"
          ></span>
        </div>
      </div>
      <div>
        {!track.loading ? (
          <div
            style={{
              display: "inline-flex",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div>🔉</div>
            <div>
              <input
                style={{ minWidth: 200 }}
                type="range"
                min="0"
                max="100"
                value={currentVolume}
                onChange={(event) => {
                  const newVolume = parseInt(event.target.value) / 100;

                  setVolume(newVolume * 100);
                  if (track.ref.current && !track.found) {
                    // track.ref.current!.volume = newVolume;
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div style={{ padding: "10px 3px" }}>
            <div className="progress-bar">
              <div className="progress-bar-value"></div>
            </div>
          </div>
        )}
      </div>
      {!track.loading ? (
        <div key={`audio-${track.hmac}`}>
          <AudioPlayer
            track={track}
            isPlaying={track.isPlaying}
            volume={currentVolume}
          />
          {/* <audio
            controls
            loop={true}
            preload="auto"
            style={{ display: "none" }}
            ref={track.ref}
            onCanPlay={() => {
              setVolume(80);
              // track.ref.current!.volume = 0.8;
            }}
          >
            <source src={track.url} type="audio/mp3" />
          </audio> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
