import { store } from "react-notifications-component";

interface DisplayNotificationParams {
  title: string;
  message: string;
}

export class NotificationUtils {
  private static showNotification({
    title,
    message,
    type,
  }: {
    title: string;
    message: string;
    type: "success" | "danger" | "info" | "default" | "warning";
  }) {
    store.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  public static showWarning({ title, message }: DisplayNotificationParams) {
    this.showNotification({ title, message, type: "warning" });
  }

  public static showError({ title, message }: DisplayNotificationParams) {
    this.showNotification({ title, message, type: "danger" });
  }
}
