import React from "react";

export const SearchNoResults = ({ noResult }: { noResult: boolean }) =>
  noResult ? (
    <>
      <p style={{ fontWeight: "bold" }}>
        Ooops, aucun résultat pour ta recherche!
      </p>
    </>
  ) : (
    <></>
  );
