import { SearchItem } from "../types/tracks";

export const TrackSelectorItem = ({
  track,
  onSelected,
}: {
  track: SearchItem;
  onSelected: (res: { track: SearchItem }) => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "5px 20px",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <img
        alt="cover"
        src={track.coverURL}
        style={{
          width: 64,
          height: 64,
          marginTop: "auto",
          marginBottom: "auto",
          objectFit: "contain",
        }}
      />
      <div
        style={{
          flexDirection: "column",
          marginLeft: 10,
          textAlign: "left",
          paddingRight: 10,
        }}
      >
        <div style={{ fontSize: "1em" }}>{track.title}</div>
        <div style={{ fontSize: "0.85em" }}>{track.artist}</div>
      </div>
      <div
        style={{ alignItems: "center", marginLeft: "auto", display: "flex" }}
      >
        <button
          style={{
            maxHeight: 30,
            padding: 8,
            borderRadius: 5,
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => onSelected({ track })}
        >
          Ajouter
        </button>
      </div>
    </div>
  );
};
