import React from "react";
import { LatestTrack, SearchItem, Track } from "../types/tracks";
import { NotificationUtils } from "./notificationUtils";
import { upsertInLatestTracks } from "./stateUtils";

export const OnSearchResultSelected = ({
  setTrackSearch,
  setTracks,
  setTrackSearchResults,
  addTrack,
  setLatestTracks,
}: {
  setTrackSearch: (s: string) => void;
  setTracks: React.Dispatch<React.SetStateAction<Track[]>>;
  addTrack: (s: {
    track: SearchItem;
  }) => Promise<{ url?: string; hmac?: string }>;
  setLatestTracks: React.Dispatch<React.SetStateAction<LatestTrack[]>>;
  setTrackSearchResults: (s: SearchItem[]) => void;
}) => {
  return ({ track }: { track: SearchItem }) => {
    setTrackSearch("");
    const trackAdd: Track = {
      id: track.id,
      name: track.title,
      artist: track.artist,
      url: undefined,
      found: false,
      loading: true,
      ref: React.createRef<HTMLAudioElement>(),
      hmac: "",
      isPlaying: false,
      thumbnailURL: track.coverURL,
    };
    setTracks((newTracks) => {
      return [...(newTracks || []), trackAdd];
    });

    addTrack({ track }).then(({ url: trackURL, hmac }) => {
      if (!trackURL) {
        setTracks((newTracks) => {
          const trackIndex = newTracks.findIndex(
            (n) => trackAdd.name === n.name
          );

          if (trackIndex >= 0) {
            newTracks.splice(trackIndex, 1);
          }

          return [...newTracks];
        });
        return;
      }

      upsertInLatestTracks({ setLatestTracks, track });

      setTracks((tracks) => {
        const trackI = tracks.findIndex((t) => t.id === track.id);

        if (trackI < 0) {
          throw new Error("Initial track not found");
        }

        const f = { ...tracks[trackI] };

        console.log("found", f);

        f.url = trackURL;
        f.loading = false;
        f.hmac = hmac!;

        const copied = [...tracks];

        copied[trackI] = f;

        return copied;
      });
    });

    setTrackSearchResults([]);
  };
};

export const addTrack = async ({ track }: { track: SearchItem }) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_API_HOST
    }/tracks/download?track_id=${encodeURIComponent(
      track.id
    )}&artist=${encodeURIComponent(track.artist)}&title=${encodeURIComponent(
      track.title
    )}&thumbnail_url=${encodeURIComponent(
      track.coverURL
    )}&hmac=${encodeURIComponent(track.hmac)}`
  ).catch((err) => {
    console.error(err);
    return undefined;
  });

  if (!response || response.status >= 500) {
    NotificationUtils.showError({
      title: "Ooops",
      message: "Une erreur technique s'est produite!",
    });

    return {};
  }

  if (response.status === 404) {
    NotificationUtils.showWarning({
      title: "Ooops",
      message: "Musique indisponible 😭",
    });

    return {};
  }

  fetch(`${process.env.REACT_APP_API_HOST}/tracks/${track.id}/use`, {
    method: "POST",
  }).catch((err) => console.error(err));

  const { url, hmac } = await response.json();

  return { url, hmac };
};

export const getLatestTracks = async () => {
  const data = await fetch(
    `${process.env.REACT_APP_API_HOST}/tracks/latest`
  ).then((res) => res.json());

  if (!data) {
    throw new Error("getLatestTrack: error while fetching latest");
  }

  const { tracks } = data;

  return tracks;
};
