import React, { useEffect, useState } from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "./App.css";
import { SearchTrackComponent } from "./components/searchTrack";
import { SelectedTrackItem } from "./components/selectedTrackItem";
import { LatestTrack, SearchItem, Track } from "./types/tracks";
import { TrackSearchResults } from "./components/trackSearchResults";

import { LatestSongs } from "./components/latestSongs";
import { SearchNoResults } from "./components/searchNoResult";
import {
  addTrack,
  getLatestTracks,
  OnSearchResultSelected,
} from "./utils/searchUtils";
import { initPlayback } from "./utils/playbackUtils";
import { ExplanationTexts } from "./components/explanationTexts";
import { GameControls } from "./components/gameControls";
// import { SpotifyModalPlaylistImport } from "./components/spotifyModalPlaylistImport";

initPlayback();

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [searchNoResult, setSearchNoResult] = useState(false);
  const [trackSearch, setTrackSearch] = useState("");

  const [trackSearchResults, setTrackSearchResults] = useState<SearchItem[]>(
    []
  );
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [latestTracks, setLatestTracks] = useState<LatestTrack[]>([]);
  const [latestTracksLoadError, setLatestTracksLoadError] =
    useState<boolean>(false);

  useEffect(() => {
    getLatestTracks()
      .then((tracks) => setLatestTracks(tracks))
      .catch((err) => {
        console.error(err);
        setLatestTracksLoadError(true);
      });
  }, []);

  return (
    <div className="App" id="app" style={{ paddingBottom: 25 }}>
      <ReactNotification />
      <header className="bg-blue-950 text-white rounded-md text-2xl p-3 m-1.5">
        <h1>Un bon mix</h1>
      </header>

      <SearchTrackComponent
        query={trackSearch}
        setQuery={setTrackSearch}
        onSearchResults={({ items }) => {
          setSearchNoResult(items.length === 0);
          setTrackSearchResults(items);
        }}
        resetResults={() => setTrackSearchResults([])}
      />

      <div
        style={{
          maxWidth: 800,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {/* Show the error message for no result */}
        <SearchNoResults noResult={searchNoResult} />
        {/* Not displayed if no results */}
        <TrackSearchResults
          searchItems={trackSearchResults}
          onSearchItemSelected={({ track }) => {
            // upsertInLatestTracks({ setLatestTracks, track });

            OnSearchResultSelected({
              addTrack,
              setTrackSearch,
              setTrackSearchResults,
              setTracks,
              setLatestTracks,
            })({ track });
          }}
        />
      </div>
      <div style={{ paddingLeft: 8, paddingRight: 8 }}>
        <h3>Sons</h3>
        {tracks?.map((track, _) => {
          return (
            <SelectedTrackItem
              key={`trackname-${track.id}`}
              gameStarted={isGameStarted}
              track={track}
              onTrackFound={({ track }) => {
                track.found = true;
                track.isPlaying = false;
                setTracks((newTracks) => [...newTracks]);
              }}
            />
          );
        })}
        <ExplanationTexts tracks={tracks || []} />
      </div>

      <GameControls
        tracks={tracks}
        isPlaying={isPlaying}
        onPlay={() => {
          setIsPlaying(true);
          setTracks([...tracks.map((t) => ({ ...t, isPlaying: !t.found }))]);
        }}
        onPause={() => {
          setIsPlaying(false);
          setTracks([...tracks.map((t) => ({ ...t, isPlaying: false }))]);
        }}
        onGameStarted={() => setIsGameStarted(true)}
        onReset={() => {
          setTracks([]);
          setIsGameStarted(false);
          setIsPlaying(false);
        }}
      />

      <LatestSongs
        onTrackSelected={({ track }) => {
          OnSearchResultSelected({
            addTrack,
            setTrackSearch,
            setTrackSearchResults,
            setTracks,
            setLatestTracks,
          })({
            track: {
              artist: track.artist,
              hmac: track.hmac,
              coverURL: track.thumbnailURL!,
              id: track.id,
              title: track.title,
            },
          });
        }}
        latestTracks={latestTracks}
        loadError={latestTracksLoadError}
        usedTracksIDs={tracks.map((t) => t.id)}
      />
    </div>
  );
}

export default App;
