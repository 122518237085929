import { Track } from "../types/tracks";

export const ExplanationTexts = ({ tracks }: { tracks: Track[] }) => {
  const items: JSX.Element[] = [];

  if (!tracks.length) {
    items.push(
      <p style={{ padding: "0 10px" }}>
        Ajoutes des musiques en les recherchant, tu dois en ajouter au minimum
        3!
      </p>
    );
  }

  if (tracks.length >= 3) {
    items.push(
      <div
        style={{
          padding: "5px 10px",
        }}
      >
        <hr></hr>
      </div>
    );
  }

  if (tracks.length > 0 && tracks.length < 3) {
    items.push(
      <p>
        Encore {3 - tracks.length} son{3 - tracks.length > 1 ? "s" : ""} à
        ajouter pour pouvoir commencer
      </p>
    );
  }

  if (tracks.length >= 3) {
    items.push(
      ...[
        <p>Let's go! Lance les musiques en appuyant sur le bouton play</p>,
        <p>
          Quand tes potes ont trouvés un son, appuies sur le bouton pour retirer
          ce son
        </p>,
      ]
    );
  }

  return <>{items}</>;
};
