import { LatestTrack, SearchItem } from "../types/tracks";

export const upsertInLatestTracks = ({
  setLatestTracks,
  track,
}: {
  setLatestTracks: React.Dispatch<React.SetStateAction<LatestTrack[]>>;
  track: SearchItem | LatestTrack;
}) => {
  setLatestTracks((tracks) => {
    const newTracks = [...tracks];
    const index = tracks.findIndex((t) => t.id === track.id);

    if (index === 0) {
      return tracks;
    } else if (index > 0) {
      newTracks.splice(index, 1);
    }

    let latestTrack: LatestTrack;

    if ((track as LatestTrack).youtubeURL) {
      latestTrack = track as LatestTrack;
    } else {
      latestTrack = {
        artist: track.artist,
        title: track.title,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        hmac: track.hmac,
        id: track.id,
        youtubeURL: "",
        thumbnailURL: "",
      };
    }

    return [latestTrack, ...newTracks];
  });
};
