import React from "react";
import { store } from "react-notifications-component";
import { SearchItem } from "../types/tracks";

export class SearchTrackComponent extends React.Component<{
  query: string;
  setQuery: (q: string) => void;
  onSearchResults: (res: { items: SearchItem[] }) => void;
  resetResults: () => void;
}> {
  async onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (this.props.query === "") {
      return;
    }

    this.props.resetResults();

    const response = await fetch(
      `${
        process.env.REACT_APP_API_HOST
      }/tracks/search?track=${encodeURIComponent(this.props.query)}`
    ).catch((err) => {
      console.error(err);

      return undefined;
    });

    if (!response || response.status >= 500) {
      store.addNotification({
        title: "Ooops",
        message: "Une erreur technique s'est produite!",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      return;
    }

    const json = await response.json();

    this.props.onSearchResults({ items: json });
  }

  render() {
    return (
      <div className="text-center m-1.5 border-2 rounded-md">
        <form onSubmit={(e) => this.onFormSubmit(e)}>
          <div className="m-1.5">Recherche ta musique</div>
          <div style={{ display: "flex" }}>
            <span style={{ margin: 2, marginRight: 12 }}>👉</span>
            <input
              type="text"
              value={this.props.query}
              style={{
                width: 200,
                margin: 2,
                display: "inline-block",
                borderRadius: 2,
                border: "none",
                fontSize: 17,
              }}
              placeholder=""
              spellCheck="false"
              autoCorrect="off"
              onChange={(e) => this.props.setQuery(e.target.value)}
            />
            <button
              type="submit"
              style={{
                cursor: "pointer",
                background: "none",
                alignSelf: "center",
                margin: 2,
                height: "100%",
                // maxHeight: 30,
                padding: "0 12px",
                borderRadius: 5,
                border: "none",
              }}
            >
              <span>🔎</span>
            </button>
          </div>
        </form>
      </div>
    );
  }
}
