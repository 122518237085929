export const initPlayback = () => {
  let ctx: any = null;
  let usingWebAudio = true;

  try {
    if (typeof AudioContext !== "undefined") {
      ctx = new AudioContext();
    } else if (typeof (window as any).webkitAudioContext !== "undefined") {
      ctx = new (window as any).webkitAudioContext();
    } else {
      usingWebAudio = false;
    }
  } catch (e) {
    usingWebAudio = false;
  }

  // context state at this time is `undefined` in iOS8 Safari
  if (usingWebAudio && ctx.state === "suspended") {
    var resume = function () {
      ctx.resume();
    };

    document.body.addEventListener("touchend", resume, false);
  }
};
