import React, { useEffect, useState } from "react";

import Howl from "howler";

import { Track } from "../types/tracks";

interface AudioProps {
  track: Track;
  isPlaying: boolean;
  volume: number;
}

export const AudioPlayer = (props: AudioProps) => {
  const [audio, setAudio] = useState<Howl.Howl>();

  if (audio === undefined && props.track.url !== undefined) {
    let url = props.track.url;

    const a = new Howl.Howl({
      src: url,
      format: ["m4a"],
      volume: 0.8,
      loop: true,
      preload: true,
      onloaderror: (id, err) => {
        alert(err);
      },
      onplayerror: (id, err) => {
        alert(err);
      },
      html5: false,
    });
    setAudio(a);
  }

  useEffect(() => {
    if (props.isPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [props.isPlaying]);

  useEffect(() => {
    // This doesn't work unfortunately on iOS mobile
    if (audio !== undefined) {
      audio.volume(props.volume / 100);
    }
  }, [props.volume]);

  return (
    <div>
      {/* <div>PLAYING: {props.isPlaying === true ? "TRUE" : "FALSE"}</div>
      <div>{audio?.playing() === true ? "TRUE" : "FALSE"}</div>
      <div>{audio?.duration()}</div>
      <button onClick={() => audio?.play()}>PLAY</button> */}
    </div>
  );
};
