import { Track } from "../types/tracks";
import { PlayButton } from "./playButton";

export const GameControls = ({
  tracks,
  onReset,
  onPause,
  onPlay,
  onGameStarted,
  isPlaying,
}: {
  tracks: Track[];
  onReset: () => void;
  onPlay: () => void;
  onPause: () => void;
  onGameStarted: () => void;
  isPlaying: boolean;
}) => {
  if (!tracks) {
    return <></>;
  }

  const hasTracksLoading =
    tracks.filter((t) => t.loading === true).length !== 0;
  const hasEnoughTracks = tracks.length >= 3;
  const hasFoundAllTracks =
    tracks.filter((t) => t.found === false).length === 0;

  if (hasTracksLoading || !hasEnoughTracks) {
    return <></>;
  }

  if (hasFoundAllTracks) {
    return (
      <button
        style={{
          padding: "5px 20px",
          borderRadius: 4,
          border: "1px solid",
        }}
        onClick={() => {
          onReset();
        }}
      >
        <div style={{ fontSize: 30 }}>⟲</div>
        <div style={{ fontSize: 20 }}>Rejouer</div>
      </button>
    );
  }

  return (
    <div
      onClick={() => {
        onGameStarted();

        if (isPlaying) {
          onPause();
        } else {
          onPlay();
        }
      }}
    >
      <PlayButton playing={isPlaying} />
    </div>
  );
};
