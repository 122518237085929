import React from "react";
import { LatestTrack } from "../types/tracks";
import InfiniteScroll from "react-infinite-scroll-component";


interface LatestSongsState {latestTracks: LatestTrack[]; hasMore: boolean}

export class LatestSongs extends React.Component<
  {
    latestTracks: LatestTrack[];
    onTrackSelected: (p: { track: LatestTrack }) => void;
    loadError: boolean;
    usedTracksIDs: string[];
  },
  LatestSongsState
> {
  private isLoading = false;

  constructor(props: {
    latestTracks: LatestTrack[];
    onTrackSelected: (p: { track: LatestTrack }) => void;
    loadError: boolean;
    usedTracksIDs: string[];
  }) {
    super(props)

    this.state = {
      latestTracks: props.latestTracks,
      hasMore: true,
    }
  }

  async fetchMoreData() {
    this.isLoading = true

    if (this.state.latestTracks.length === 0) {
      this.isLoading = false;
      return
    }
    const last = this.state.latestTracks[this.state.latestTracks.length - 1];

    const data = await fetch(
      `${process.env.REACT_APP_API_HOST}/tracks/latest?after=${encodeURIComponent(last.updatedAt)}`
    ).then((res) => res.json());

    if (data.tracks.length === 0) {
      this.isLoading = false;
      this.setState({ hasMore: false });
    
      return
    }
    this.state.latestTracks.push(...data.tracks)

    this.isLoading = false;
    this.setState({ latestTracks: this.state.latestTracks })
  }

  shouldFetch() {
    if (this.isLoading || !this.state.hasMore) {
      return false
    }
    const element = document.getElementById('latest-songs-infinite-scroll')!;
    const boundingClient = element.getBoundingClientRect()
    const currentVis = boundingClient.y + boundingClient.height;

    const visiblePosition = window.scrollY + window.innerHeight;

    return visiblePosition + 100 > currentVis
   
  }

  componentDidUpdate() {
    if (this.props.latestTracks.length > 0 && this.state.latestTracks.length === 0) {
      this.setState({ latestTracks: this.props.latestTracks }, () => {
        if (this.shouldFetch()) {
          this.fetchMoreData()
        }
      })
    }

    if (this.shouldFetch()) {
      this.fetchMoreData()
    }
  }

  render() {
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left",
          maxWidth: 375,
          paddingRight: 15,
          paddingLeft: 15,
        }}
        id="latest-songs-infinite-scroll"
      >
        <h4>Les derniers sons utilisés 👇</h4>
        {this.props.loadError ? (
          <div style={{ color: "red" }}>
            Impossible de charger les derniers sons 😢
          </div>
        ) : (
          <></>
        )}
        <InfiniteScroll
          hasMore={this.state.hasMore && !this.props.loadError}
          dataLength={this.state.latestTracks.length}
          next={() => this.fetchMoreData()}
          loader={<h4>Loading...</h4>}
        >
          {this.props.latestTracks.map((track) => {
          const isDisabled = this.props.usedTracksIDs.indexOf(track.id) >= 0;
          return (
            <div style={{ lineHeight: 2, display: "flex", marginBottom: 2 }} key={`latest-song-${track.id}`}>
              <span style={{ paddingRight: 10, alignSelf: "center" }}>
                {track.title} - {track.artist}
              </span>
              <div style={{ marginLeft: "auto", display: "flex" }}>
                <button
                  className="icon-btn"
                  style={{
                    marginLeft: "auto",
                    width: 30,
                    height: 30,
                    alignSelf: "center",
                    cursor: isDisabled ? "initial" : "pointer",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                  disabled={isDisabled}
                  onClick={() => {
                    if (!isDisabled) {
                      this.props.onTrackSelected({
                        track,
                      });
                    }
                  }}
                >
                  <div className="icon">➕</div>
                </button>
              </div>
            </div>
          );
        })}
        </InfiniteScroll>
        
      </div>
    );
  }
}
